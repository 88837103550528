import { randomFromArray, randomInt } from './utils/util'
import randomWords from 'random-words'

const sampleNames = [
  'Donald Zhu',
  'Erin Charlton',
  'Sukhraj Billen',
  'Tingyu Fang',
  'Hwa-Jin Jun',
  'June Jung',
  'Olivia Jurkovic',
  'Arshdeep Kang',
  'Colin Lawrence',
  'Julia Song',
  'Yingran Xu',
  'Nima Yeganeh',
  'Ellen Zhang',
  'Ali Qadeer',
  'Richrd Hunt',
  'Nancy Snow',
  'Roderick Grant',
  'Keith Rushton',
  'Sarah Diamond',
  'Barack Obama',
  'Joe Biden'
].map(name => name.split(' '))

const sampleBios = [
  `My name is Anastasiia Krasnova. I am a graphic designer interested in print & digital as well as interactive design and animation. My design ideas are inspired by different cultures and world issues. I believe that design is a powerful tool and a universal language that allows us to communicate across different cultures and countries, erasing borders, and that is what drives me for my profession. I always strive to learn new things and challenge myself to continuously grow as a designer and to be able to create a positive lasting impact through my work.`,
  `Hello, I'm Jessie! I am a graphic designer, an explorer, a lactose-intolerant ice cream lover, enjoys tropical weather with the occasional torrential downpour, and the future owner of a dog named Meatball. Fueled by high energy, curiosity, and purpose, I am always eager to take on any and all kinds of challenges. Working on projects that make an impact in how we perceive the world is something that excites and fulfills me, even if it means dabbling in the unknown. My interests include packaging design, advertising, UI/UX, branding, product design, and PR campaigns.`,
  `Hey there, Angela here! I am a Toronto based multidisciplinary designer, I've always been a part of the creative world- studying dance, music, art, and now focusing on my career in design. I am very passionate about my craft and my process and I’m always looking for new ways to challenge myself!. I enjoy working within areas of design such as - typography, editorial design, experimental typeface design, package design, and any new project/task that can allow me to create work that inspires myself, others and creates positive change.`,
  `My name is Sarah Feng. As a graphic designer with 5+ years experience, it might seem strange that I get excited thinking about colors, typography and cascading style sheets, but my passion for all aspects of graphic design has remained strong throughout my design history.`,
  `Hello everyone! My name is Marina:) I am a graphic designer at the beginning of my career journey. I truly adore colour and not afraid to play with different techniques and approaches. I am also interested in editorial,typography, and UI/ UX design.`,
]

const sampleBrief = [
  `Say HELLO to ‘What’s Cooking’. My thesis project is a cooking app which is currently still a prototype and work-in-progress. I am eager to continue this journey in bringing my project to life. My dream is for this to become a fully functional app and with dedication, hard work and A LOT more time anything is possible. I was trying to find the balance between home cooking and ordering in. I can go from cooking a five course meal to barley having a second to eat. I myself struggle to fit cooking into my ‘busy’ life. When creating ‘What’s Cooking’, I wished there was a platform that combined all cooking apps and resources into one. My goal was to create a place where everyone of any cooking level can come together and not only learn how to cook but find the time to cook at home.`,
  `As a person living with chronic invisible illness and disability, I decided to create a thesis focusing on raising awareness regarding invisible illness & disability in order to reform the definition and image of the disabled body. (IN)VISIBILITY is an entirely handmade and hand-bound interactive experience. Featuring hardcover-concealed spiral binding, and 9 pages of analog animation. The covers and pages of this book were hand cut, glued, and pressed. The book describes and visualizes some of the symptoms of a few invisible illness and disabilities. Flip through the entire book here.`,
  `The Manic Pixie Dream Girl trope describes the role of a quirky upbeat girl solely existing to help the depressed male protagonist to find meaning in life again. Coined in 2007 by Nathan Rabin, the term has seen a shift from being a valid criticism used to question the representation of women in film, to an anti-feminist term used to overgeneralize and objectify women. “It’s Not Me, It’s You” is a collection of print and time-based pieces, through interaction viewers are invited to explore how the Manic Pixie Dream Girl has been shaped by us, as well as how we have been shaped by it.`,
  `KILL THE LIGHTS is a campaign comprised of a collection of smaller projects aimed to shed a light on the issues surrounding light pollution on human health.\nThe wordmark for KILL THE LIGHTS uses Beretta Sans, skewed slightly to capture the qualities of neon signage and the repetitive forms reminiscent of blurry street lights at night. The identity was created using cyanotype, a photographic printing process that uses light to create images. The campaign uses blue throughout, inspired by the Prussian blue pigment produced by the oxidation of the photosensitive solution during the image-making process.`,
  `For my thesis I've been exploring the question How do you Exhibit a Ghost Discipline? I refer to graphic design as a ghost discipline, meaning it “only exists when other subjects exist first" (Stuart Bailey). So how do you exhibit and frame graphic design, when it's graphic design inherently doesn't exist, it is a frame that holds content. My year-long exploration consisted of several projects all exploring what an exhibition for ghostly content looks like. Through 3 projects, I explored the practice of exhibition design, along with developing a formula that can help me answer a question graphic designers have been struggling and stuck with.`
]


export const sampleTags = [
  'Branding',
  'Campaigns',
  'Coding',
  'Exhibition Design',
  'Game Design',
  'Generative Design',
  'Illustration',
  'Information Design',
  'Motion Graphics',
  'Publication',
  'Social Justice',
  'Typography',
  'UI/UX',
  'Video',
  'Wayfinding',
  'Web Design',
  'Art Therapy',
  'Concept Art',
  'Crafts',
  'Creative Coding',
  'Cultural Empowerment',
  'Fabrication',
  'Film',
  'Graphic Novel',
  'History',
  'Mixed Media',
  'Packaging Design',
  'Poetry',
  'Poster Design',
  'Printing',
  'Social Media',
  'Time Based Media',
  'Typeface Design',
]

const instructors = [
  'Keith Rushton',
  'Richard Hunt and Ali Qadeer',
  'Lewis Nicholson',
  'N/A'
]

const socials = [
  'Portfolio',
  'Instagram',
  'Behance',
  'LinkedIn'
]

const data = Array(20).fill(0).map((_, i) => ({
  firstName: sampleNames[i][0],
  lastName: sampleNames[i][1],
  bio: sampleBios[i % sampleBios.length],
  brief: sampleBrief[i % sampleBrief.length],
  instructors: randomFromArray(instructors),
  socials: socials.filter(() => !randomInt(2)),
  tags: Array(randomInt(2, 8)).fill(0).map(() => `${sampleTags[randomInt(sampleTags.length)]}`),
  title: randomWords(randomInt(2, 8)).join(' '),
  thumbnail: `/images/${i + 1}.png`,
  images: Array(randomInt(5, 10)).fill(0).map(() => `/images/${randomInt(1, 22)}.png`),
  videos: [
    'https://www.youtube.com/watch?v=gACPdcvXt3M',
    'https://www.vimeo.com/608334799'
  ]
}))

export default data
