import { useRef, useState, useEffect } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'
import useBreakPoint from '../hooks/useBreakPoint'
import breakpoints from '../utils/breakpoints'
import TagsDesktop from './tagsDesktop'
import TagsMobile from './tagsMobile'
import _ from 'lodash'

function Tags({ tags, filters, handleTagClick, handleClearClick }) {
  const [tagIsExpanded, setTagIsExpanded] = useState(false)
  const tagContainerRef = useRef(null)
  const dimensions = useWindowDimensions()

  useEffect(() => {
    if (!tagContainerRef.current) return _.noop

    const handleClick = e => {
      if (
        tagContainerRef.current &&
        !tagContainerRef.current.contains(e.target) &&
        !e.target.classList.contains('tag-clear')
      ) setTagIsExpanded(false)
    }

    document.body.addEventListener('click', handleClick)
    return () => document.body.removeEventListener('click', handleClick)
  }, [tagContainerRef])

  const handleTagToggleClick = () => setTagIsExpanded(!tagIsExpanded)

  const mobileBreakpoint = breakpoints[0]
  return useBreakPoint(dimensions,
    <TagsDesktop
      tags={tags}
      filters={filters}
      isExpanded={tagIsExpanded}
      containerRef={tagContainerRef}
      handleTagClick={handleTagClick}
      handleTagToggleClick={handleTagToggleClick}
      handleClearClick={handleClearClick} />,
    {
      [mobileBreakpoint]:
        <TagsMobile
          tags={tags}
          filters={filters}
          handleTagClick={handleTagClick} />
    }
  )
}

export default Tags