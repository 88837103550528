import axios from 'axios'
import config from '../config.json'

axios.defaults.baseURL = config.api

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.stauts >= 400 &&
    error.response.status <= 500
  if (!expectedError)
    console.log(error)

  return Promise.reject(error)
})

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
}