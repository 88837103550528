import { ReactComponent as BehanceSvg } from '../svg/behance.svg'
import { ReactComponent as InstagramSvg } from '../svg/instagram.svg'
import { ReactComponent as LinkedInSvg } from '../svg/linkedin.svg'
import { ReactComponent as PortfolioSvg } from '../svg/portfolio.svg'
import { parseSocials } from '../utils/data'
import SvgComponent from './svgComponent'

function ProjectSocials({ socials }) {
  const svgs = {
    behance: BehanceSvg,
    instagram: InstagramSvg,
    linkedin: LinkedInSvg,
    portfolio: PortfolioSvg,
  }

  socials = parseSocials(socials)

  return (
    <div className='student-social-wrapper'>
      {Object.keys(socials).map((key, i) =>
        <a
          className='student-social-link'
          href={socials[key]}
          target='_blank'
          rel='noreferrer'
          key={i}>
          <SvgComponent
            svg={svgs[key.toLowerCase()]}
            className='student-social-inverted'
            social={key.toLowerCase()} />
        </a>)
      }
    </div>
  )
}

export default ProjectSocials