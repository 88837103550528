import { useEffect, useState } from 'react'
import { appendImgSrc } from '../utils/util'

function GridImage({ fallback, webpName }) {
  const [src, setSrc] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.onerror = () => setSrc(appendImgSrc(fallback))
    img.src = webpName
    img.onload = () => setSrc(img.src)
  }, [])

  return src && <img className='grid-cell-image' src={src} alt='' />
}

export default GridImage