import { useEffect, useRef, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import './style/main.css'
import Home from './components/home'
import Project from './components/project'
import { gsap } from 'gsap'
import { getData } from './services/dataServices'
import usePreload from './hooks/usePreload'
import { getThumbnailWebps } from './utils/data'
import { appendImgSrc } from './utils/util'

function App() {
  const [filters, setFilters] = useState([])
  const [data, setData] = useState([])
  const comp = useRef(null)
  const navigate = useNavigate()
  gsap.ticker.fps(12)

  const setList = usePreload()

  useEffect(() => {
    getData().then(data => {
      data = _.shuffle(data)
      setData(data)
    })
  }, [])

  useEffect(() => {
    if (!data.length) return _.noop
    const thumbnailList = getThumbnailWebps(data)
    const projectList = data.map(studentData => studentData.images
      .map(imageSrc => appendImgSrc(imageSrc))).flat()
    setList({ thumbnails: thumbnailList, projects: projectList })
  }, [data])

  const handleTagClick = tag => {
    const prevFilters = [...filters]
    const index = prevFilters.indexOf(tag)
    if (index !== -1) prevFilters.splice(index, 1)
    else prevFilters.push(tag)
    setFilters(prevFilters)
  }

  const handleCellTagClick = (e, tag) => {
    e.stopPropagation()
    window.scroll({ top: 0, behavior: 'smooth' })
    const newFilter = filters.includes(tag) ? [] : [tag]
    setFilters(newFilter)
  }

  const handleProjectTagClick = tag => {
    setFilters([tag])
    navigate('/')
  }

  const handleClearClick = () => setFilters([])

  return (
    <div className='app' ref={comp}>
      <Routes>
        <Route path='/' element={
          <Home
            comp={comp}
            data={data}
            filters={filters}
            handleTagClick={handleTagClick}
            handleCellTagClick={handleCellTagClick}
            handleClearClick={handleClearClick} />} />
        {data.length && data.map((studentData, i) => <Route
          key={i}
          path={`project/${studentData.firstName}_${studentData.lastName}`}
          element={<Project
            comp={comp}
            data={studentData}
            navigate={navigate}
            handleTagClick={handleProjectTagClick}
          />} />)}
      </Routes>
    </div>
  )
}

export default App
