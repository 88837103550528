import config from '../config.json'

export const vw = (percentage = 100) => (window.innerWidth * percentage) / 100
export const vh = (percentage = 100) => (window.innerHeight * percentage) / 100

const parseRandomArgs = (a, b) => {
  if (Array.isArray(a)) {
    if (a.length === 2) return a
    else if (a.length === 1) return [0, a[0]]
  } else if (!b && b !== 0) return [0, !a && a !== 0 ? 1 : a]
  else return [a, b]
}

export const randomFloat = (a, b) => {
  let args = parseRandomArgs(a, b) // [min, max]
  return Math.random() * (args[1] - args[0]) + args[0]
}

export const randomInt = (a, b) => {
  let args = parseRandomArgs(a, b) // [min, max]
  args[0] = Math.ceil(args[0])
  args[1] = Math.floor(args[1])
  return Math.floor(Math.random() * (args[1] - args[0]) + args[0])
}

export const mod = (min, max) => (max + (min % max)) % max
export const cap = (value, min, max) => min + mod(value - min, max - min + 1)

export const randomSway = (current, [minStep, maxStep], [minBound, maxBound]) => {
  current = parseFloat(current)
  const step = randomFloat(minStep, maxStep) * (randomInt(2) || -1)
  let newValue = current + step
  if (newValue > maxBound || newValue < minBound) newValue = current - step
  return newValue
}

export const randomFromArray = array => array[randomInt(array.length)]

const YOUTUBE = 'youtube'
const VIMEO = 'vimeo'
export const parseVideo = src => {
  if (src.match(/^https:\/\/www\.youtube|https:\/\/youtube/))
    return ['https://www.youtube.com/embed/' + src.split('?v=')[1], YOUTUBE]

  if (src.match(/^https:\/\/www\.vimeo|https:\/\/vimeo/))
    return ['https://player.vimeo.com/video/' + src.split('com/')[1], VIMEO]

  if (src.match(/^https:\/\/youtu.be\//))
    return ['https://www.youtube.com/embed/' + src.split('be/')[1], YOUTUBE]

  return [src, undefined]
}

export const parseRgb = string => string.match(/\((.*)\)/)[1].split(',')
export const toRgb = stringArray => `rgb(${stringArray.join(', ')})`

export const appendImgSrc = src => `${config.api}${src}`