import _ from 'lodash'
import { titleCase } from 'title-case'

export const filterData = student => student.images.length


export const getTags = studentArray => _.uniq(studentArray.reduce((prev, curr) => {
  prev.push(...curr.tags)
  return prev
}, [])).map(tag => titleCase(tag))

export const parseSocial = social => social.match(/href="(.*)" target/) || []

export const parseSocials = socials => {
  const keys = Object.keys(socials)
  const result = {}
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = socials[key]
    const url = key === 'porfolio' ? value : parseSocial(value)[1]
    if (url) result[key] = url
  }

  return result
}

export const getThumbnailWebp = studentData => `/thumbnails/${`${studentData.firstName.toLocaleLowerCase()}_${studentData.lastName.toLocaleLowerCase()}`.replaceAll(' ', '_')}.webp`

export const getThumbnailWebps = studentArray => studentArray.map(student => getThumbnailWebp(student))