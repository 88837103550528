import _ from 'lodash'
import http from './httpServices'
import { filterData } from '../utils/data'

export async function getData() {
  try {
    const response = await http.get('/all')
    const data = response.data.map(student => {
      const {
        field_given_names: firstName,
        field_last_name: lastName,
        title,
        field_project_description: brief,
        field_workshop_: instructors,
        field_short_biography: bio,
        field_tags,
        field_additional_,
        field_email: email,
        field_instagram_link: instagram,
        field_behance_link: behance,
        field_linked_in_link: linkedin,
        field_portfolio_site_link: portfolio,
        field_thumbnail_image: thumbnail,
        field_project_images: images,
        video
      } = student
      return {
        title: _.unescape(title),
        brief,
        bio,
        instructors: instructors === 'Did not take Workshop' ? 'N/A' : instructors,
        firstName,
        lastName,
        tags: [...field_tags.split(', '), ...field_additional_.split(', ')].filter(t => t),
        socials: {
          instagram, behance, linkedin, portfolio
        },
        email,
        thumbnail,
        images: images.split(',').filter(t => t),
        videos: video.split(', ').filter(t => t)
      }
    })
    return data.filter(filterData)
  } catch (error) {
    console.error(error)
  }
}

