import GridText from './gridText'
import GridImage from './gridImage'
import { useNavigate } from 'react-router-dom'
import { getThumbnailWebp } from '../utils/data'

function GridCell({ data, filters, handleCellTagClick }) {
  const navigate = useNavigate()
  const handleOnClick = studentName => {
    window.scrollTo({ top: 0 })
    navigate(`project/${studentName}`)
  }

  return (
    <div className='grid-cell' onClick={() => handleOnClick(`${data.firstName}_${data.lastName}`)}>
      <GridText {...data} filters={filters} handleCellTagClick={handleCellTagClick} />
      <GridImage
        fallback={data.thumbnail}
        webpName={getThumbnailWebp(data)} />
    </div>
  )
}

export default GridCell