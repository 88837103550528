import useAnimate from '../hooks/useAnimate'
import { parseRgb, randomFloat, randomSway, toRgb } from '../utils/util'


function Header({ comp, count = 3, children, classPrefix, config = {}, ...props }) {

  const {
    weight: weightConfig = [[35, 100], [20, 300]],
    opacity: opacityConfig = [[0.1, 0.2], [0.5, 0.9]],
    rgb: rgbConfig = [[30, 100], [0, 255]],
    shadow: shadowConfig = [[5, 15], [10, 50]]
  } = config

  const animationCallback = titleRef => {
    const style = getComputedStyle(titleRef.current)
    const newWeight = randomSway(style.fontWeight, ...weightConfig)
    const newOpacity = randomSway(style.opacity, ...opacityConfig)

    const rgb = parseRgb(style.color)
    const newRgb = rgb.map(value => randomSway(value, ...rgbConfig))
    const newRgbString = toRgb(newRgb)

    const shadowSizeArray = style.textShadow.split(' ')
    const shadowSize = shadowSizeArray[shadowSizeArray.length - 1]
    const newshadowSize = randomSway(shadowSize, ...shadowConfig)
    const newshadowString = `${newRgbString} 0px 0px ${newshadowSize}px`

    return {
      fontWeight: newWeight,
      opacity: newOpacity,
      color: newRgbString,
      textShadow: newshadowString,
      duration: randomFloat(5, 7)
    }
  }

  const titleRefs = [
    useAnimate(comp, animationCallback),
    useAnimate(comp, animationCallback),
    useAnimate(comp, animationCallback)
  ]

  return (
    <div className={`${classPrefix}-wrapper`} {...props}>
      <h1 className={`${classPrefix} ${classPrefix}-placeholder header-placeholder title`}>{children}</h1>
      {Array(count).fill(0).map((_, i) =>
        <h1 key={i} className={`title ${classPrefix}`} ref={titleRefs[i]} aria-hidden>{children}</h1>
      )}
    </div>
  )
}

export default Header