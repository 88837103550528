import { useState, useEffect } from 'react'
import Tag from './tag'
import _ from 'lodash'
import { appendImgSrc, parseVideo } from '../utils/util'
import Header from './header'
import ImageZoom from './imageZoom'
import useWindowDimensions from '../hooks/useWindowDimensions'
import breakpoints from '../utils/breakpoints'
import useBreakPoint from '../hooks/useBreakPoint'
import ProjectInstructor from './projectInstructor'
import ProjectSocials from './projectSocials'
import { parseHtml } from '../services/parserServices'

function Project({ comp, data, navigate, handleTagClick }) {
  const { firstName, lastName, title, brief, bio, instructors, tags, socials, email, images, videos } = data
  const [zoomedImage, setZoomedImage] = useState(null)
  const dimensions = useWindowDimensions()

  //jank ui fix 
  useEffect(() => {
    const { style } = document.body
    if (dimensions.width <= breakpoints[0]) {
      style.overflow = ''
      return _.noop
    }
    style.overflow = 'hidden'
    return () => style.overflow = ''
  }, [dimensions])

  const handleZoomIn = imgSrc => setZoomedImage(imgSrc)
  const handleZoomOut = () => setZoomedImage(null)

  return (
    <div className='project'>
      <ImageZoom imgSrc={zoomedImage} onZoomOut={handleZoomOut} />
      <div className='project-header-container'>
        <Header
          comp={comp}
          classPrefix='project-header'
          config={{
            weight: [[15, 25], [20, 120]],
            shadow: [[1, 3], [10, 20]]
          }}
          onClick={() => navigate('/')}>
          GD23
        </Header>
      </div>
      <div className='project-content'>
        <div className='project-text-scroll-wrapper'>
          <div className='project-text-wrapper'>
            <h1 className='project-title'>{title}</h1>
            <div className='project-tags'>
              {tags.map(tag => <Tag
                name={tag}
                handleTagClick={() => handleTagClick(tag)}
                key={tag} />)}
            </div>
            <div className='project-brief'>{parseHtml(brief)}</div>
            {useBreakPoint(dimensions, null,
              { [breakpoints[0]]: <ProjectInstructor instructors={instructors} /> })}
            <div className='student-info'>
              <div className='student-name-wrapper'>
                <h2 className='student-name'>
                  {firstName + ' ' + lastName}
                </h2>
                {useBreakPoint(dimensions, <ProjectSocials socials={socials} />,
                  { [breakpoints[0]]: null })}
              </div>
              <p className='student-email'>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
              <div className='student-bio'>{parseHtml(`“${bio}”`)}</div>
              {useBreakPoint(dimensions, null,
                { [breakpoints[0]]: <ProjectSocials socials={socials} /> })}
            </div>
            {useBreakPoint(dimensions, <ProjectInstructor instructors={instructors} />,
              { [breakpoints[0]]: null })}
          </div>
        </div>
        <div className='project-media-scroll-wrapper'>
          <div className='project-media-wrapper'>
            {images.map((imageSrc, i) => <img
              className='project-image project-media'
              src={appendImgSrc(imageSrc)}
              onLoad={e => e.target.style.display = 'initial'}
              alt=''
              onClick={() => handleZoomIn(imageSrc)}
              key={`image-${i}`} />)}
            {videos.map((videoSrc, i) => {
              const [src, type] = parseVideo(videoSrc)
              return <iframe
                className={`project-video project-media project-iframe-${type || 'unknown'}`}
                title={`video-${i + 1}`}
                src={src}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                key={`video-${i}`} />
            })}
          </div>
        </div>


      </div>
    </div>
  )
}

export default Project