import GridCell from './gridCell'

function Grid({ data, filters, handleCellTagClick }) {
  const filterTag = tags => !!tags.filter(tag => filters.includes(tag)).length || !filters.length

  return (
    <div className='grid'>
      {data.map((studentData, i) => {
        const { tags } = studentData
        return filterTag(tags) ?
          <GridCell
            key={i}
            data={studentData}
            filters={filters}
            handleCellTagClick={handleCellTagClick} />
          : null
      })}
    </div>
  )
}

export default Grid