import Header from './header'
import Grid from './grid'
import Tags from './tags'
import { getTags } from '../utils/data'

function Home({ comp, data, filters, handleTagClick, handleCellTagClick, handleClearClick }) {
  return (
    <>
      <div className='header'>
        <Header
          comp={comp}
          classPrefix='website-title' >
          OCAD U Graphic Design 2023
        </Header>
      </div>
      <Grid
        data={data}
        filters={filters}
        handleCellTagClick={handleCellTagClick} />
      <Tags
        tags={getTags(data)}
        filters={filters}
        handleTagClick={handleTagClick}
        handleClearClick={handleClearClick} />
    </>
  )
}

export default Home
