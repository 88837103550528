import { useEffect, useState } from 'react'
import _ from 'lodash'

const preloadImage = src => {
  return new Promise(res => {
    const img = new Image()
    img.onload = () => res()
    img.onerror = err => {
      console.error(err)
      res(err) // i know
    }
    img.src = src
  })
}

const usePreload = () => {
  const [list, setList] = useState(null)
  useEffect(() => {
    if (!list) return _.noop
    const thumbnailPromises = list.thumbnails.map(thumbnail => preloadImage(thumbnail))
    Promise.all(thumbnailPromises).then(() => {
      console.log('Loaded all thumbnails.')
      const projectPromises = list.projects.map(project => preloadImage(project))
      Promise.all(projectPromises).then(() => console.log('Loaded all images.'))
    })
  }, [list])
  return setList
}

export default usePreload