import { gsap } from 'gsap'

export const animate = (ref, getNewConfig) => {
  function animate() {
    const animation = gsap.to(ref.current, {
      ease: 'power1.inOut',
      ...getNewConfig(),
    })
    animation.eventCallback('onComplete', animate)
  }
  animate()
}
