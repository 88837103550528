import parse, { domToReact } from 'html-react-parser'

const headers = ['h1', 'h2', 'h3', 'h4', 'h5']

let wrapNextInParagraph = false
export const parseHtml = htmlString => parse(htmlString.trim(), {
  replace: node => {
    const { name, next, type, data, children } = node
    if (name === 'br' && next.type === 'text') {
      wrapNextInParagraph = true
      return <></>
    }
    if (type === 'text') {
      if (!data.trim())
        return <></>
      else if (wrapNextInParagraph) {
        wrapNextInParagraph = false
        return <p>{data}</p>
      }
    }
    if (headers.includes(name)) return <p>{domToReact(children)}</p>
    return node
  }
})
