
function GridText({ firstName, lastName, tags, title, filters, handleCellTagClick }) {
  return (
    <div className='grid-cell-text'>
      <div className='grid-cell-info'>
        <p className='grid-cell-name'>{firstName} {lastName}</p>
        <h2 className='grid-cell-title'>{title}</h2>
      </div>
      <div className='grid-cell-tags'>
        {tags.map((tag, i) => <div
          key={i}
          className={`grid-cell-tag${filters.includes(tag) ? ' selected' : ''}`}
          onClick={e => handleCellTagClick(e, tag)}>
          {tag}
        </div>)}
      </div>
    </div>
  )
}

export default GridText