function useBreakPoint({ width }, fallback, breakpoints = {}, config = { bound: 'max' }) {
  const { bound } = config
  const boundIsMax = bound === 'max'
  const orderedBreakpoints = Object.keys(breakpoints)
    .sort(boundIsMax ? (a, b) => b - a : (a, b) => a - b)
    .map(breakpoint => parseInt(breakpoint))

  const matchedBreakpoint = orderedBreakpoints.find(breakpoint =>
    boundIsMax ? width <= breakpoint : width >= breakpoint
  )

  return matchedBreakpoint ? (breakpoints[matchedBreakpoint] || null) : fallback
}

export default useBreakPoint