import React from 'react'
import { appendImgSrc } from '../utils/util'

function ImageZoom({ imgSrc, onZoomOut }) {
  return (
    <div className={`image-zoom${imgSrc ? ' shown' : ''}`} onClick={onZoomOut}>
      {imgSrc && <img src={appendImgSrc(imgSrc)} alt='' />}
    </div>
  )
}

export default ImageZoom