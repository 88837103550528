import { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { animate } from '../utils/animation'
import _ from 'lodash'

const useAnimate = (comp, animationCallback, preshake = true) => {
  const elemRef = useRef(null)
  useLayoutEffect(() => {
    if (!comp.current) return _.noop
    if (preshake) gsap.to(elemRef.current, {
      ...animationCallback(elemRef),
      duration: 0
    })

    const context = gsap.context(() => {
      animate(elemRef, () => animationCallback(elemRef))
    }, comp)
    return () => context.revert()
  }, [comp])
  return elemRef
}

export default useAnimate 