import { useEffect, useRef, useState } from 'react'
import { sampleTags } from '../mockData'
import Tag from './tag'

function TagsMobile({ filters, handleTagClick }) {
  const tagsMobileRef = useRef(null)
  const [scrollNeeded, setScrollNeeded] = useState(false)

  const handleMobileTagClick = tag => {
    setScrollNeeded(true)
    handleTagClick(tag)
  }

  useEffect(() => {
    if (tagsMobileRef.current && scrollNeeded) {
      tagsMobileRef.current.scrollIntoView()
      setScrollNeeded(false)
    }
  }, [scrollNeeded])
  return (
    <div className='tags-mobile' ref={tagsMobileRef}>
      {sampleTags.map((tag, i) => (
        <Tag
          name={tag}
          filters={filters}
          handleTagClick={handleMobileTagClick}
          key={i} />
      ))}
    </div>
  )
}

export default TagsMobile