function Tag({ name, filters, handleTagClick }) {

  return (
    <div
      role='button'
      className={`tag${filters && filters.includes(name) ? ' selected' : ''}`}
      onClick={() => handleTagClick(name)}>
      {name}
    </div>
  )
}

export default Tag