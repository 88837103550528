import Tag from './tag'

function TagsDesktop({ tags, filters, isExpanded, containerRef, handleTagClick, handleTagToggleClick, handleClearClick }) {
  return (
    <div
      ref={containerRef}
      className={`tag-container${isExpanded ? ' tag-container-expanded' : ''}`}>
      <div className='tag-header' >
        {!!filters.length && isExpanded &&
          <div className='tag-clear' onClick={handleClearClick} >
            clear
          </div>
        }
        <div className='tag-toggle' onClick={handleTagToggleClick}>
          filters {`${isExpanded ? '×' : '+'}`}
        </div>
      </div>

      <div className='tags'>
        {tags.map((tag, i) => (
          <Tag
            name={tag}
            filters={filters}
            handleTagClick={handleTagClick}
            key={i} />
        ))}
      </div>
    </div>
  )
}

export default TagsDesktop